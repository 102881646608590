// TODO: Cleanup the type on dateRestored after open-api merges in for Archive controller
export const getArchivesStatus = (restoring: boolean, dateRestored: Date | null | string | undefined): 'Restoring' | 'Restored' | 'Archived' => {
  if (restoring) {
    return 'Restoring';
  }
  if (dateRestored && !restoring) {
    return 'Restored';
  }
  return 'Archived';
};
