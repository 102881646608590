import { BlockBlobTier } from '@azure/storage-blob';
import { pick } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { useGate } from 'statsig-react';

import {
  authenticateUpload, startUpload,
} from './transfer/transferManager';
import { ITransferProgress, TransferProgress } from './transfer/TransferProgress';
import { UploadError } from '../StudioError';
import { transferServerApi } from 'api/transfersServerApi';
import { StatsigGates } from 'StatsigGates';

export interface IFileWithPath extends File {
  path: string;
}

export interface ICreateTransfer {
  projectId?: number,
  transferRequestId?: number
  files: IFileWithPath[]
  selectedRecipients: string[],
  description: string,
  archive: boolean
  requireMFA: boolean;
  workOrderIdentifier?: string;
  enableSamply: boolean;
}

function getPaths(filePath: string): { relativePath: string, path: string } {
  const pathArr = filePath.split('/');
  pathArr.pop()!;
  const relativePath = pathArr.join('/');

  return {
    relativePath: relativePath.substring(1, relativePath.length),
    path: filePath.split('/')[0],
  };
}

export function useAzureUpload(): {
  createTransfer: (createTransferData: ICreateTransfer) => Promise<void>;
  progress: ITransferProgress;
} {
  const [progress, setProgress] = useState<ITransferProgress>(TransferProgress.default().toObject());
  const abortController = useRef(new AbortController());

  useBlocker(() => {
    if (progress?.isActive) {
      const shouldBlock = !window.confirm('Are you sure you want to leave this page? Your transfer will be cancelled.');
      if (!shouldBlock) {
        abortController.current.abort('User left page.');
      }
      return shouldBlock;
    }

    return false;
  });
  // This is drilled down because you cannot call hooks from nested methods  const reuseBlobs = useGate(StatsigGates.REUSE_AZURE_BLOBS).value;
  const reuseBlobs = useGate(StatsigGates.REUSE_AZURE_BLOBS).value;
  const createTransfer = useCallback(async ({
    files,
    projectId,
    transferRequestId,
    selectedRecipients,
    description,
    archive = false,
    requireMFA,
    workOrderIdentifier,
    enableSamply,
  }: ICreateTransfer): Promise<void> => {
    const filesWithPath = files.map((file) => {
      const { relativePath } = getPaths(file.path);
      return { ...pick(file, 'name', 'size', 'path', 'type'), relativePath };
    });

    if (archive && (requireMFA || enableSamply)) {
      throw new UploadError('Cannot create an archive that requires MFA or Samply', `Archive: ${archive}, Samply: ${enableSamply}`);
    }

    const { data: newTransfer } = projectId
      ? await transferServerApi.POST('/api-v2/transfers', {
        body: {
          projectId: projectId!,
          files: filesWithPath,
          recipients: selectedRecipients,
          transferType: 1,
          description,
          archive,
          requireMFA,
          enableSamply,
          workOrderIdentifier,
        },
      })
      : await transferServerApi.POST('/api-v2/transfer-requests/{transferRequestId}/transfer', {
        params: {
          path: {
            transferRequestId: Number(transferRequestId),
          },
        },
        body: {
          files: filesWithPath,
        },
      });

    if (files.length !== newTransfer?.files.length) {
      throw new UploadError('Server file count missmatch', `Attempted to upload ${files.length} files but only ${newTransfer?.files.length} were created on the server.`);
    }

    const auth = await authenticateUpload(newTransfer.transferId);
    if (auth === undefined) {
      throw new UploadError('Failed to authenticate upload', 'Failed to authenticate upload');
    }

    await startUpload({
      abortController: abortController.current,
      auth,
      tier: archive ? BlockBlobTier.Archive : BlockBlobTier.Cold,
      blobs: newTransfer.files,
      files,
      concurrency: 4,
      operation: 'upload',
      reuseBlobs,
      onProgress: (p: TransferProgress) => {
        setProgress(p.toObject());
      },
    });

    await transferServerApi.PUT('/api-v2/transfers/{transferId}/certify', {
      params: {
        query: {
          enableSamply,
        },
        path: {
          transferId: newTransfer.transferId,
        },
      },
    });
  }, [reuseBlobs]);

  return {
    createTransfer,
    progress,
  };
}
