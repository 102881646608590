import { Box, Flex, Text } from '@chakra-ui/react';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { Else, If, Then } from 'react-if';

import { ProjectCard } from './ProjectCard';
import { studioServerApi } from 'api/studioServerApi';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';

export default function ProjectList(): JSX.Element {
  const completedProjectsQuery = useApiQuery({
    queryKey: ['projects', 'my-projects', 'showCompletedProjects=true'],
    queryFn: () => studioServerApi.GET('/api/projects/my-projects', {
      params: {
        query: {
          showCompletedProjects: true,
        },
      },
    }),
  });

  const projectsQuery = useApiQuery({
    queryKey: ['projects', 'my-projects', 'showCompletedProjects=false'],
    queryFn: () => studioServerApi.GET('/api/projects/my-projects', {
      params: {
        query: {
          showCompletedProjects: false,
        },
      },
    }),
  });

  const projects = useMemo(() => {
    if (!completedProjectsQuery.apiResult?.data || !projectsQuery.apiResult?.data) return [];
    return orderBy(projectsQuery.apiResult?.data?.concat(completedProjectsQuery.apiResult?.data), (project) => new Date(project.ScheduledDate ?? new Date()), 'asc');
  }, [projectsQuery, completedProjectsQuery]);

  return (
    <QuerySuspense queries={[projectsQuery.queryResult]}>
      <If condition={projectsQuery.apiResult?.data?.length === 0}>
        <Then>
          <Text> No sessions found</Text>
        </Then>
        <Else>
          <Box mt='50px'>
            <Flex direction='column' gap='25px'>
              {projects.map((project) => (
                <ProjectCard
                  key={project.ProjectNumber!}
                  projectIdentifier={project.ProjectIdentifier!}
                  resourceName={project.ResourceName ?? 'N/A'}
                  resourceImageUrl={project.ResourceImageUrl ?? ''}
                  resourceTitle={project.ResourceTitle ?? 'N/A'}
                  resourceManagerEmail={project.ManagerEmail ?? undefined}
                  scheduledDate={project.ScheduledDate ?? undefined}
                  artistName={project.ArtistName ?? 'N/A'}
                  projectName={project.ProjectName ?? 'N/A'}
                  showAddContactButton
                />
              ))}
            </Flex>
          </Box>
        </Else>
      </If>
    </QuerySuspense>
  );
}
