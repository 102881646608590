import * as prisma from '@prisma/client';

import { components } from 'studio-api-clients/transfers-server';

export enum FileReviewStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum FileMixType {
  MAIN = 'MAIN',
  ALTERNATE = 'ALTERNATE',
  TV_TRACK = 'TV_TRACK',
}

export type Resources = prisma.Resources;
export type Artists = prisma.Artists;
export type Downloads = prisma.Downloads & { Contact: prisma.Contacts };
export type FileDto = components['schemas']['FileDto'];
export type Files = Omit<prisma.Files, 'FileSize'> & { ReviewStatus: FileReviewStatus, MixType: FileMixType, FileSize: string };
export type Subscription = prisma.Subscriptions & { Resource: Resources };
export type ProjectContacts = prisma.ProjectContacts;
export type Projects = prisma.Projects;
export type TransferRequests = prisma.TransferRequests;
export type TransferRestoreRequests = prisma.TransferRestoreRequests;
export type Transfers = prisma.Transfers & { Project: Partial<Projects> };
export type SmsEnrollment = prisma.SmsEnrollments;
export type Contacts = prisma.Contacts;

// export type Sessions = prisma.Sessions;

export type TransferCTRow = components['schemas']['TransferCTRowDto'];

export type TransferRequestCTRow = {
  project: {
    id: string;
    projectNumber: number;
    externalSourceId: string | null,
    name: string,
  },
  request: {
    id: number,
    name: string,
  },
  artist?: string,
  from: string,
  date: Date,
  state: string | null,
  team?: string,
  recipients: NameEmailPair[],
  stats: {
    filesCount: number,
    filesSize: number,
    transferCount: number,
  },
};

export type ProjectStats = {
  fileCount: number,
  sizeTotal: number,
  transferCount: number,
};

export type ProjectCTRow = {
  project: {
    id: number,
    externalSourceId: string | null,
    name: string,
  };
  date: Date;
  artist?: string;
  stats: {
    fileCount: number,
    sizeTotal: number,
    transferCount: number,
  }
  resource?: string;
};

export type NameEmailPair = components['schemas']['NameEmailPairDto'];

export type ProjectOverviewResponse = {
  name: string;
  id: string;
  externalSourceId: string | null;
  projectNumber: number;
  stats: ProjectStats;
  date: Date;
  artist?: string;
  team: {
    name?: string;
    members: NameEmailPair[];
  }
};

export type TransferOverviewResponse = components['schemas']['TransferOverviewDto'];

export type ArchiveStatus = 'Restoring' | 'Restored' | 'Archived';

export type ArchiveOverviewResponse = {
  id: number,
  name: string,
  from: NameEmailPair
  date: Date,
  stats: {
    fileCount: number,
    fileSize: number,
  },
  status: ArchiveStatus,
};

export type ArchivedTransfersCTRow = {
  transferId: string,
  dateModified: Date,
  modifiedBy: string,
  restoring: boolean,
  restoredBy: string | null,
  dateRestored: Date | null,
  archived: boolean,
  filesCount: number | null,
  filesSize: bigint | null,
  artist: string | null,
  project: {
    id: number,
    externalSourceId: string | null,
    name: string,
  },
};

export type TransferWithMeta = components['schemas']['TransferWithMetaDto'];

export type OtpaInfoResponse = {
  needsOtpa: boolean; // true if user currently needs to enter OTPA to access the site
  otpaEnrolled: boolean;
  otpaEnrollmentDate: string | null;
};

export type TransferAuthentications = components['schemas']['TransferAuthenticationDto'];
