import { createContext } from 'react';

import { FileDto } from 'types/api';

interface IFilesPageContext {
  fileList: FileDto[];
  isReadOnly: boolean;
  isArchive: boolean;
}

const FileListContext = createContext<IFilesPageContext>({
  isReadOnly: false,
  fileList: [],
  isArchive: false,
});

export default FileListContext;
