import {
  Button,
  Flex,
  Heading,
  Img,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useMemo, useState } from 'react';
import {
  Case,
  Default,
  Switch, When,
} from 'react-if';
import { useParams } from 'react-router';

import { ArchiveOverview } from './ArchiveOverview';
import { transferServerApi } from 'api/transfersServerApi';
import ArchiveIcon from 'assets/icons/archive.svg';
import DownloadIcon from 'assets/icons/download.svg';
import RestoreIcon from 'assets/icons/restore.svg';
import AsyncButton from 'components/form/AsyncButton';
import QuerySuspense from 'components/QuerySuspense';
import { TabWithIcon } from 'components/TabWithIcon';
import { useApiQuery } from 'hooks/useApiQuery';
import { useAzureDownload } from 'hooks/useAzureDownload';
import { ActivityIcon } from 'pages/transfers/files-page/components/ActivityIcon';
import { FileIcon } from 'pages/transfers/files-page/components/FileIcon';
import { FileViewTabs } from 'pages/transfers/files-page/components/FileViewTabs';
import FileListContext from 'pages/transfers/files-page/FileListContext';
import { ProjectOverview } from 'pages/transfers/project-overview-page/components/ProjectOverview';
import { ActivitiesTable } from 'pages/transfers/projects-page/ActivitiesTable';
import {
  TransferRestoreRequests,
} from 'types/api';
import { getArchivesStatus } from 'utils/status';

export default function ArchiveFilesPage(): JSX.Element {
  const { archiveId } = useParams();
  const transferQuery = useApiQuery({
    queryKey: ['transfer-query', archiveId],
    queryFn: () => transferServerApi.GET('/api-v2/transfers/{transferId}', {
      params: {
        path: {
          transferId: Number(archiveId),
        },
      },
    }),
  });
  const transferFileListQuery = useApiQuery({
    queryKey: ['transfer-file-list', archiveId],
    queryFn: () => transferServerApi.GET('/api-v2/transfers/{transferId}/file-list', {
      params: {
        path: {
          transferId: Number(archiveId!),
        },
      },
    }),
  });
  const transferStatus = useMemo(() => {
    if (!transferQuery.apiResult?.data) return;
    return getArchivesStatus(transferQuery.apiResult?.data.Restoring, transferQuery.apiResult?.data?.DateRestored);
  }, [transferQuery.apiResult?.data]);

  const fileListContext = useMemo(() => ({
    isReadOnly: transferStatus !== 'Restored',
    fileList: transferFileListQuery.apiResult?.data || [],
    isArchive: false,
  }), [transferFileListQuery.apiResult?.data, transferStatus]);

  const [tabIndex, setTabIndex] = useState(0);

  const toast = useToast();

  const {
    downloadBlobs,
  } = useAzureDownload();
  async function downloadBlob(id: string, artist: string | undefined): Promise<void> {
    const fileListRes = await transferServerApi.GET('/api-v2/transfers/{transferId}/file-list', {
      params: {
        path: {
          transferId: Number(id),
        },
      },
    });
    await downloadBlobs({ blobs: fileListRes.data, artist });
  }

  async function restoreArchive(): Promise<void> {
    const restoringArchiveRequest = await axios.post<TransferRestoreRequests>(`/file-api/archives/${archiveId}/restore`);
    toast({
      title: restoringArchiveRequest.data.Fulfilled ? 'Archive is already restored' : 'Archive is restoring',
      status: restoringArchiveRequest.data.Fulfilled ? 'warning' : 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    });
  }

  return (
    <QuerySuspense queries={[transferQuery.queryResult, transferFileListQuery.queryResult]}>
      <FileListContext.Provider value={fileListContext}>
        <Flex direction='column' gap='50px' width='100%' justifyContent='center'>
          <Flex direction='row' justifyContent='space-between'>
            <Flex height='30px' align='start' gap='10px'>
              <Img src={ArchiveIcon} w='20px' h='20px' />
              <Heading fontWeight='700' fontSize='20px'>Archive #{archiveId}</Heading>
            </Flex>
            <Flex align='end'>
              <Switch>
                <Case condition={transferStatus === 'Restored'}>
                  <AsyncButton
                    spinAfterPromiseResolvedSuccess={false}
                    onClick={() => downloadBlob(archiveId!, transferQuery.apiResult?.data?.Project?.ArtistIdentifier || undefined)} // come back to this because it might be wrong?
                    leftIcon={<Img src={DownloadIcon} />}
                    padding='15px 25px'
                  >
                    Download
                  </AsyncButton>
                </Case>
                <Case condition={transferStatus === 'Restoring'}>
                  <Button
                    isLoading
                    padding='15px 25px'
                    loadingText='Restoring'
                  />
                </Case>
                <Default>
                  {/* transferStatus === 'Archived' */}
                  <AsyncButton
                    spinAfterPromiseResolvedSuccess={false}
                    onClick={restoreArchive}
                    leftIcon={<Img src={RestoreIcon} />}
                    padding='15px 25px'
                  >
                    Restore Archive
                  </AsyncButton>
                </Default>
              </Switch>
            </Flex>
          </Flex>

          <When condition={!!transferQuery.apiResult?.data?.Project}>
            <ProjectOverview projectId={transferQuery.apiResult?.data?.Project?.ProjectNumber!} />
          </When>
          <ArchiveOverview archiveId={archiveId} />
          <Tabs isLazy lazyBehavior='unmount' index={tabIndex} onChange={setTabIndex}>
            <TabList width='100%' height='40px'>
              <TabWithIcon
                icon={FileIcon()}
                label='Files'
              />
              <TabWithIcon
                icon={ActivityIcon()}
                label='Activities'
              />
            </TabList>
            <TabPanels>
              <TabPanel>
                <FileViewTabs transfer={transferQuery.apiResult?.data} />
              </TabPanel>
              <TabPanel>
                <ActivitiesTable transferId={archiveId} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </FileListContext.Provider>
    </QuerySuspense>
  );
}
