import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import AddIcon from 'assets/icons/AddIconBlack.svg';
import EmailIcon from 'assets/icons/EmailIcon.svg';
import { getDate } from 'utils/formatter';

export type ProjectCardProps = {
  projectIdentifier: string;
  resourceName: string;
  resourceImageUrl: string;
  resourceTitle: string;
  resourceManagerEmail?: string | null;
  scheduledDate?: string | Date;
  artistName: string;
  projectName: string;
  showAddContactButton?: boolean;
};

export function ProjectCard({
  projectIdentifier,
  resourceName,
  resourceImageUrl,
  resourceTitle,
  resourceManagerEmail,
  scheduledDate,
  artistName,
  projectName,
  showAddContactButton,
}: ProjectCardProps): JSX.Element {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClick = () => {
    const url = `/booking/sessions/${projectIdentifier}/overview`;
    navigate(url);
  };

  return (
    <Stack
      minW='372px'
      maxW='372px'
      height='auto'
      backgroundColor='#0F1118'
      border='1px solid #A0AEC0'
      borderRadius='15px'
      padding='30px'
      key={projectIdentifier}
      onClick={handleClick}
      sx={{
        '&:hover': {
          borderColor: 'gold',
          borderWidth: '1px',
          borderStyle: 'solid',
          cursor: 'pointer',
        },
      }}
    >
      <Flex gap='20px'>
        <Avatar name={resourceName} src={resourceImageUrl} mr='5px' w='60px' h='60px' />
        <Flex direction='column'>
          <Text fontSize='18px' fontWeight='600'>{resourceName}</Text>
          <Text color='gray.500'>{resourceTitle}</Text>
          {resourceManagerEmail && (
          <Flex gap='5px' alignItems='center' mb='10px'>
            <Image src={EmailIcon} width='15px' />
            <Text textAlign='left' color='gray.500' fontSize='12px'>
              <Link href={`mailto:${resourceManagerEmail}`}>
                {resourceManagerEmail}
              </Link>
            </Text>
          </Flex>
          )}

        </Flex>
      </Flex>
      <Box width='90%'>
        <Flex direction='column' gap='15px'>
          <Flex display='flex' gap='2.9rem'>
            <Text color='gray.500'>Date</Text>
            <Text>{getDate(scheduledDate)}</Text>
          </Flex>
          <Flex display='flex' gap='2.5rem'>
            <Text color='gray.500'>Artist</Text>
            <Text whiteSpace='normal' overflow='hidden' textOverflow='ellipsis'>{artistName}</Text>
          </Flex>
          <Flex display='flex' gap='1.4rem'>
            <Text color='gray.500'>Session</Text>
            <Text whiteSpace='normal' overflow='hidden' textOverflow='ellipsis'>{projectName}</Text>
          </Flex>
        </Flex>
      </Box>
      {showAddContactButton && (
      <Button
        onClick={handleClick}
        backgroundColor='gray.400'
        borderRadius='8px'
        mt='10px'
        w='100%'
        h='45px'
        _hover={{ backgroundColor: 'gray.500' }}
      >
        <Image src={AddIcon} mr='10px' />
        <Text>Add Session Contact</Text>
      </Button>
      )}
    </Stack>
  );
}
