import { parseBuffer } from 'music-metadata';

export type AudioMetadata = {
  wav_metadata_format?: string;
  wav_metadata_channels?: string;
  wav_metadata_sample_rate?: string;
  wav_metadata_byte_rate?: string;
  wav_metadata_block_align?: string;
  wav_metadata_bits_per_sample?: string;
  wav_metadata_audio_hash_md5?: string;
  wav_metadata_audio_container?: string;
  wav_metadata_audio_duration_seconds?: number;
  wav_metadata_audio_codec?: string;
};

export async function calculateAudioMetadata(file: File): Promise<Omit<AudioMetadata, 'wav_metadata_audio_hash_md5'> | undefined> {
  if (!file.type.startsWith('audio/')) {
    console.log(`File ${file.name} is not an audio file. Skipping audio metadata calculation.`);
    return undefined;
  }

  try {
    const fileBuffer = await file.arrayBuffer();
    const uintFileBuffer = new Uint8Array(fileBuffer);
    const metadata = await parseBuffer(uintFileBuffer);

    return {
      wav_metadata_bits_per_sample: metadata.format.bitsPerSample?.toString(),
      wav_metadata_byte_rate: metadata.format.bitrate ? Math.round(metadata.format.bitrate).toString() : '',
      wav_metadata_channels: metadata.format.numberOfChannels?.toString(),
      wav_metadata_format: metadata.format.codec?.toString(),
      wav_metadata_sample_rate: metadata.format.sampleRate?.toString(),
      wav_metadata_audio_container: metadata.format.container?.toString(),
      wav_metadata_audio_codec: metadata.format.codec?.toString(),
      wav_metadata_audio_duration_seconds: metadata.format.duration ? Math.round(metadata.format.duration) : undefined,
    };
  } catch (e: any) {
    if (e.message?.includes('MIME-type not supported')) {
      console.log(`File ${file.name} is not an audio file. Skipping audio metadata calculation.`);
    }
  }
  return undefined;
}
