import {
  Box, Flex, Image, Link, Text,
} from '@chakra-ui/react';

import { PublicResource } from 'api/studioServerTypes';
import EmailIcon from 'assets/icons/EmailIcon.svg';

export default function EngineerHeaderCard({
  OnlineName, OnlineTitle, SmallImageUrl, ManagerEmail,
}: PublicResource & { ManagerEmail?: string | null }): JSX.Element | null {
  return (
    <Box
      backgroundColor='#0F1118'
      width='auto'
      minHeight='100px'
      borderRadius='15'
      position='static'
      overflow='visible'
      mt='35px'
    >
      <Flex
        flexDir='column'
        alignItems='center'
        p='7'
        mt='-80px'
      >
        <Image
          border='solid 10px #171922'
          src={SmallImageUrl!}
          alt={OnlineName!}
          borderRadius='50%'
          width='90px'
        />
        <Text textAlign='center' fontSize='16px' mt={2}>{OnlineName!}</Text>
        <Text textAlign='center' color='gray.500' fontSize='14px'>{OnlineTitle!}</Text>
        {ManagerEmail && (
          <Flex gap='5px' alignItems='center' marginTop='10px'>
            <Image src={EmailIcon} width='15px' />
            <Text textAlign='left' color='gray.500' fontSize='12px'>
              <Link href={`mailto:${ManagerEmail}`}>
                {ManagerEmail}
              </Link>
            </Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
