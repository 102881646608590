import {
  MenuItem, MenuList,
  useToast,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Row, RowSelectionState, Table, createColumnHelper,
} from '@tanstack/react-table';
import { ContextMenu } from 'chakra-ui-contextmenu';
import { format } from 'date-fns';
import {
  useState,
} from 'react';
import { When } from 'react-if';

import EditFileMetadataModal from './EditFileMetadataModal';
import { generateFilesMetadata, getFileSize } from './helpers/fileTags';
import { VersionKeys, getVersionDisplay } from './helpers/version';
import { IndeterminateCheckbox } from './IndeterminateCheckbox';
import { transferServerApi } from 'api/transfersServerApi';
import FileIcon from 'assets/icons/simple-file.svg';
import { getContactDisplayName } from 'components/ContactDisplayName';
import DateComponent from 'components/DateComponent';
import QuerySuspense from 'components/QuerySuspense';
import {
  DateHeader,
  Duration,
  FileHeader,
  FileSize,
  FromHeader,
  ReviewHeader,
  SongHeader,
  Technical,
  VersionHeader,
} from 'components/table/headers';
import StudiumTable from 'components/table/StudiumTable';
import { useApiQuery } from 'hooks/useApiQuery';
import useUserContext from 'hooks/useUserContext';
import EditFileReviewModal from 'pages/ct/files-page/components/EditFileReviewModal';
import { Files } from 'types/api';

interface FileTableProps {
  apiPath: string;
  invisibleColumns?: TransfersTableColumns,
  selectedFiles: {
    FileId: number;
    FileName: string;
  }[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<{
    FileId: number;
    FileName: string;
  }[]>>;
  projectNumber: number;
  createdByFilter?: boolean;
}

export type TransfersTableColumns = {
  CreatedBy?: boolean,
  DateTime?: boolean,
  TransferId?: boolean,
  FileName?: boolean,
  TechInformation?: boolean,
};

export function handleMultiselect(row: Row<any>, table: Table<any>): void {
  const { rows } = table.getCoreRowModel();
  const { rowSelection } = table.getState();
  const lastSelectedRowIndex = Math.max(...Object.keys(rowSelection).map(Number)) ?? 0;
  const rowsToSelect = rows.slice(Math.min(row.index, lastSelectedRowIndex), Math.max(row.index, lastSelectedRowIndex));
  const newSelections: RowSelectionState = rowsToSelect.reduce((state: RowSelectionState, rowToSelect: Row<any>) => ({ ...state, [rowToSelect.index]: true }), {});
  table.setRowSelection(() => ({ ...rowSelection, ...newSelections }));
}

export function handleGlobalCheckClick(e: unknown, table: Table<any>, setSelectedFileIds: React.Dispatch<React.SetStateAction<{
  FileId: number;
  FileName: string;
}[]>>): void {
  const toggleEventHandler = table.getToggleAllRowsSelectedHandler();
  toggleEventHandler(e);

  const { rows } = table.getCoreRowModel();

  const selectedFiles = rows.filter((row) => !row.getIsSelected()).map((row) => ({
    FileName: row.original.FileName,
    FileId: row.original.FileId,
  }));

  setSelectedFileIds(selectedFiles);
}

function getReviewStatus(row: {
  original: {
    ReviewStatus: string | null,
    ReviewedByProjectContactIdentifier?: string | null,
    ReviewedByProjectContact?: {
      Contact: {
        FirstName: string,
        LastName: string,
        Email: string } }
  }
}): string {
  if (!row.original.ReviewStatus) {
    return '-';
  }

  return `${row.original.ReviewStatus}${row.original.ReviewedByProjectContactIdentifier ? ` (${getContactDisplayName(row.original.ReviewedByProjectContact?.Contact)})` : ''}`;
}

export function FileTable({
  apiPath, invisibleColumns, selectedFiles, setSelectedFiles, projectNumber, createdByFilter = false,
}: FileTableProps): JSX.Element {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const queryClient = useQueryClient();

  const projectIdentifierQuery = useApiQuery<{ project: { ProjectIdentifier: string }, client: { CreditLimit: number } | null }, any, any>({
    queryKey: [projectNumber],
    queryFn: () => transferServerApi.GET('/api-v2/projects/{projectNumber}/identifier', {
      params: { path: { projectNumber: Number(projectNumber) } },
    }),
  });

  const { isStudioMember } = useUserContext();

  const toast = useToast();

  const columnHelper = createColumnHelper<Files & { Version: VersionKeys | null } & { SongName: string | null } >();
  const transferColumns = [
    columnHelper.display({
      id: 'select',
      // eslint-disable-next-line react/no-unstable-nested-components
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: (e) => handleGlobalCheckClick(e, table, setSelectedFiles),
          }}
        />
      ),
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    }),
    columnHelper.accessor('CreatedBy', {
      id: 'CreatedBy',
      header: FromHeader,
      cell: ({ row }) => (() => row.original.CreatedBy
      )(),
      meta: {
        enableFilter: createdByFilter,
        filterAccessorKey: 'CreatedBy',
        placeholder: 'Uploader',
        filterHasIcon: true,
      },
    }),
    columnHelper.accessor('DateCreated', {
      cell: (info) => (() => (
        <DateComponent date={info.getValue()} />
      ))(),
      header: DateHeader,
    }),
    columnHelper.accessor('FileName', {
      cell: (info) => info.getValue(),
      header: FileHeader,
    }),
    columnHelper.accessor('SongName', {
      cell: ({ row }) => (() => row.original.SongName ?? '-'
      )(),
      header: SongHeader,
      id: 'SongName',
      meta: {
        enableFilter: true,
        filterAccessorKey: 'SongName',
        placeholder: 'Song Name',
        filterHasIcon: !createdByFilter,
      },
    }),
    columnHelper.accessor('Version', {
      cell: ({ row }) => (() => getVersionDisplay(row)
      )(),
      header: VersionHeader,
      id: 'Version',
      meta: {
        enableFilter: true,
        filterAccessorKey: 'Version',
        placeholder: 'Version',
      },
    }),
    columnHelper.display({
      cell: ({ row }) => (() => (
        <> {row.original.AudioDurationSeconds ? format(row.original.AudioDurationSeconds * 1000, 'm:ss') : '-'} </>
      ))(),
      header: Duration,
      id: 'Duration',
    }),
    columnHelper.display({
      cell: ({ row }) => (() => (
        <> {getFileSize(row.original)} </>
      ))(),
      header: FileSize,
      id: 'FileSize',
    }),
    columnHelper.display({
      cell: ({ row }) => (() => (
        <> {generateFilesMetadata(row.original)} </>
      ))(),
      header: Technical,
      id: 'Technical',
    }),
    columnHelper.display({
      cell: ({ row }) => (() => getReviewStatus(row))(),
      header: ReviewHeader,
      id: 'Review',
    }),
  ];

  async function updateFileVersions(): Promise<void> {
    if (!selectedFiles.length) {
      toast({
        title: 'Select files to update.',
        status: 'warning',
        duration: 3000,
        position: 'top',
        isClosable: true,
      });
      return;
    }
    try {
      await transferServerApi.PUT('/api-v2/files/batch-version-update', {
        body: selectedFiles.map((file) => file.FileId),
      });
      toast({
        title: 'Attempted to update file versions.',
        status: 'success',
        duration: 3000,
        position: 'top',
        isClosable: true,
      });
      await queryClient.invalidateQueries({ queryKey: ['file-table'] });
    } catch (error) {
      toast({
        title: 'Failed to update file versions.',
        status: 'warning',
        duration: 3000,
        position: 'top',
        isClosable: true,
      });
    }
  }

  async function copyFileNames(): Promise<void> {
    const fileNames = selectedFiles.map((file) => file.FileName);
    if (navigator.clipboard !== undefined) {
      await navigator.clipboard.writeText(fileNames.join('\r\n'));
      toast({
        title: 'File names copied to clipboard.',
        status: 'success',
        duration: 3000,
        position: 'top',
        isClosable: true,
      });
    } else {
      toast({
        title: 'Couldn\'t copy file names.',
        status: 'warning',
        duration: 3000,
        position: 'top',
        isClosable: true,
      });
    }
  }

  function onRowClick(row: Row<any>, table?: Table<any>, e?: React.MouseEvent<any, any>): void {
    if (!table) return;
    const fileId = row.original.FileId;
    setSelectedFiles((prevSelectedFiles) => {
      if (prevSelectedFiles.find((f) => f.FileId === fileId)) {
        return prevSelectedFiles.filter((id) => id.FileId !== fileId);
      }
      return [...prevSelectedFiles, { FileId: fileId, FileName: row.original.FileName }];
    });
    // if (e?.shiftKey) {
    //   // TODO: Implement Shift + Click
    //   handleMultiselect(row, table);
    // }
  }

  return (
    <ContextMenu<HTMLDivElement>
      renderMenu={() => (
        isStudioMember
          ? (
            <MenuList>
              {selectedFiles.length
                ? [
                  <MenuItem onClick={() => setOpenEditModal(true)}>Edit Metadata</MenuItem>,
                  <MenuItem onClick={() => setOpenReviewModal(true)}>Add Review</MenuItem>,
                  <MenuItem onClick={() => updateFileVersions()}>Version Automatically</MenuItem>,
                  <MenuItem onClick={() => copyFileNames()}>Copy File Names</MenuItem>,
                ]
                : <MenuItem disabled>No files selected</MenuItem>}
            </MenuList>
          ) : null
      )}
    >
      {(ref) => (
        <div ref={ref}>
          <StudiumTable
            queryKeys={['file-table']}
            columns={transferColumns}
            apiPath={apiPath}
            handleRowClick={onRowClick}
            emptyTableState={{
              message: 'No files to display.',
              icon: FileIcon,
            }}
            stateKey='state'
            variant='compact'
            disablePagination
            invisibleColumns={invisibleColumns}
          />
          <QuerySuspense queries={[projectIdentifierQuery.queryResult]}>
            <When condition={isStudioMember && openEditModal}>
              <EditFileMetadataModal
                isOpen
                onClose={() => {
                  setOpenEditModal(false);
                }}
                projectIdentifier={projectIdentifierQuery.apiResult?.data?.project.ProjectIdentifier}
                files={selectedFiles}
              />
            </When>
            <When condition={isStudioMember && openReviewModal}>
              <EditFileReviewModal
                isOpen
                onClose={() => {
                  setOpenReviewModal(false);
                }}
                projectIdentifier={projectIdentifierQuery.apiResult?.data?.project.ProjectIdentifier}
                files={selectedFiles}
              />
            </When>
          </QuerySuspense>
        </div>
      )}
    </ContextMenu>
  );
}

export default FileTable;
