import {
  Box, Button, Flex, Img, Text,
} from '@chakra-ui/react';
import { When } from 'react-if';
import { useNavigate, useParams } from 'react-router';

import { HoldStatusBannerProps } from 'api/interfaces';
import TransferOnHoldIcon from 'assets/icons/transfer-on-hold.svg';
import useUserContext from 'hooks/useUserContext';

export function HoldStatusBanner({ holdStatus, transfer }: HoldStatusBannerProps): JSX.Element | null {
  const { isStudioMember } = useUserContext();
  const navigate = useNavigate();

  if (!holdStatus || !holdStatus.isOnHold) {
    return null;
  }

  const contactIdentifier = transfer?.Project?.BillingContactIdentifier;

  const message = isStudioMember
    ? holdStatus.studioDescription ?? `This transfer is on hold. Credit Hold: ${holdStatus.isCreditHold}`
    : holdStatus.clientDescription ?? 'This transfer is on hold. Please contact the project coordinator.';

  const hasUnpaidInvoice = Boolean(
    holdStatus.unpaidInvoice?.invoiceBalance && holdStatus.unpaidInvoice.invoiceBalance > 0,
  );

  const canPayInvoice = !isStudioMember && hasUnpaidInvoice && contactIdentifier;

  return (
    <Box
      bg='orange.50'
      border='1px solid'
      borderColor='orange.200'
      borderRadius='10px'
      p='20px'
      mb='20px'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <Flex align='center' gap='16px'>
        <Img src={TransferOnHoldIcon} alt='Transfer on Hold' boxSize='24px' />
        <Box>
          <Text fontWeight='bold' fontSize='16px' color='orange.800'>
            Transfer on Hold
          </Text>
          <Text mt='8px' color='orange.700' fontSize='14px' lineHeight='20px'>
            {message}
          </Text>
          <When condition={hasUnpaidInvoice}>
            <Text mt='8px' color='orange.700' fontSize='14px' lineHeight='20px'>
              Unpaid Invoice Balance: $
              {holdStatus.unpaidInvoice!.invoiceBalance!.toFixed(2)}
            </Text>
          </When>
        </Box>
      </Flex>
      <When condition={canPayInvoice}>
        <Button
          bg='orange.600'
          color='white'
          fontWeight='bold'
          size='sm'
          height='40px'
          width='140px'
          _hover={{ bg: 'orange.700' }}
          _active={{ bg: 'orange.800' }}
          onClick={() => {
            navigate(`/portal/pay-invoice/${contactIdentifier}/${holdStatus.unpaidInvoice!.ProjectInvoiceIdentifier}/`);
          }}
        >
          Pay now
        </Button>
      </When>
    </Box>
  );
}
