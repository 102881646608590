import createClient, { FetchResponse } from 'openapi-fetch';

import { authMiddleware, errorMiddleware } from './serverApiMiddleware';
import { paths } from 'studio-api-clients/transfers-server';

export type HttpMethod =
  | 'get'
  | 'put'
  | 'post'
  | 'delete'
  | 'options'
  | 'head'
  | 'patch'
  | 'trace';

export type PathsWithMethod<
  Paths extends {},
  PathnameMethod extends HttpMethod,
> = {
  [Pathname in keyof Paths]: Paths[Pathname] extends {
    [K in PathnameMethod]: any;
  }
    ? Pathname
    : never;
}[keyof Paths];

export const transferServerApi = createClient<paths>({
  baseUrl: '/',
});

transferServerApi.use(
  authMiddleware,
  errorMiddleware,
);
